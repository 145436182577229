import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" alt="An image showing two different avatars, a user and an organization." src="https://user-images.githubusercontent.com/586552/203206390-f57e83e5-6950-4036-8e67-869ca99f510c.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use avatars to represent users, organizations, bots, or teams. On GitHub, avatars are either rounded squares or circles.`}</p>
    <h2>{`Options`}</h2>
    <h3>{`Size`}</h3>
    <p>{`Avatars start at 16px and increment by base-4 until 32px. At 32px, the scale switches to base-8 up to 48px.
64px is the largest size in this scale.`}</p>
    <img width="960" alt="An image showing avatars ranging in size from smallest to largest." src="https://user-images.githubusercontent.com/586552/207139770-429f7327-c3e5-4e38-aa58-185a03d82304.png" />
    <h3>{`Shape`}</h3>
    <p>{`Avatars appear as two different shapes, each with their own functional purpose.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Circle avatars`}</strong>{` represent individual people.`}</li>
      <li parentName="ul"><strong parentName="li">{`Square avatars`}</strong>{` represent non-human entities, such as bots, teams, or organizations`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Avatar" mdxType="AccessibilityLink" />
    <h2>{`Related components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/avatar-pair"
        }}>{`Avatar pair`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/avatar-stack"
        }}>{`Avatar stack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-avatar"
        }}>{`Skeleton avatar`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      